<template>
  <el-card class="battery-page-wrapper page-container">
    <search-bar class="title-search-bar" slot="header" @inquire="inquire" @appendBattery="appendBattery"></search-bar>
    <b-floor :data="batteryList" :page="tableConfig.page" :size="tableConfig.size" :tHeight="100" @handleEdit="handleEdit" @handleDelete="handleDelete"></b-floor>
    <PaginationBar :table-config="tableConfig" @change:pageNumber="handleCurrentChange" />
    <el-dialog :close-on-click-modal="false" :title="setModel ? '编辑电池信息' : '添加电池信息'" :visible.sync="dialogVisible" width="40%" :before-close="handleClose" @open="setCurrentValue">
      <b-dialog ref="Battety" :setModel="setModel" :CPList="corporationGroup" :ProjectList="projectList" @get:getProjectList="getProjectList"></b-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import SearchBar from './component/SearchBar.vue';
import BFloor from './component/Floor.vue';
import PaginationBar from '@/components/Pagination/index.vue';
import BDialog from './component/UDialog.vue';
import { getBatteryList, increaseBattery, onChangeBatteryInfoByBatteryId, deleteBatteryInfoByBatteryId } from '@/api/battery';
import { getCorporationBySelf } from '@/api/accraditation.js';
import { queryProjectListByCpID } from '@/api/project.js';
import Mixin from '../Mixin';
import statusInclude from '@/utils/statusCode';

export default {
  name: 'Battery',
  mixins: [Mixin],
  components: {
    SearchBar,
    BFloor,
    PaginationBar,
    BDialog,
  },
  data() {
    return {
      setModel: 0,

      tHeight: 0,
      query: '',
      dialogVisible: false,
      batteryList: [],
      corporationGroup: [],
      projectList: [],
      batteryInformation: {},
      tableConfig: {
        page: 1,
        size: 20,
        total: 0,
      },
    };
  },
  created() {
    this.getCorporations();
  },
  mounted() {
    this.getBatteryList({ ...this.tableConfig });
  },
  methods: {
    /**
     * @description: 查询电池信息
     * @param {*} id
     * @return {*}
     * @author: 篆愁君
     */
    inquire(sn) {
      this.query = sn;
      this.getBatteryList({ ...this.tableConfig, query: sn });
    },
    appendBattery() {
      this.dialogVisible = true;
      this.setModel = 0;
    },
    /**
     * @description: 每页条数改变时触发 选择一页显示多少行
     * @param {*} val
     * @return {*}
     */
    handleCurrentChange(page) {
      this.tableConfig.page = page;
      this.getBatteryList({ ...this.tableConfig });
    },

    async handleDelete(batterySN) {
      const { code, reason } = await deleteBatteryInfoByBatteryId(batterySN);
      if (statusInclude(code)) {
        this.$message({ type: 'success', message: '操作成功' });
      } else {
        this.$message({ type: 'warning', message: reason });
      }
      this.getBatteryList({ ...this.tableConfig });
    },

    handleEdit(data) {
      this.setModel = 1;
      this.batteryInformation = data;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.$refs.Battety.resetForm();
      this.batteryInformation = {};
    },

    setCurrentValue() {
      setTimeout(() => {
        this.$refs.Battety.batteryInformation = JSON.parse(JSON.stringify(this.batteryInformation));
      });
    },

    // 提交电池数据
    submit() {
      this.$refs.Battety.submit().then((response) => {
        if (response) {
          if (this.setModel === 0) {
            increaseBattery(response).then((response) => {
              const { code, reason } = response;
              if (statusInclude(code)) {
                this.$message({ type: 'success', message: reason });
                this.dialogVisible = false;
              } else {
                this.$message({ type: 'warning', message: reason });
              }
            });
          } else {
            res.newBatterySN = res.batterySN;
            onChangeBatteryInfoByBatteryId(res).then((res) => {
              console.log(res);
            });
          }
        } else {
          this.$message.error('请完善电池信息');
        }
      });
    },
    /**
     * @description: 获取窗口显示高度
     * @param {*}
     * @return {*}
     */
    getHeight() {
      this.tHeight = document.getElementsByTagName('body')[0].offsetHeight - this.$store.state.settings.offset;
    },

    // 请求企业列表信息
    async getCorporations(mobile = '') {
      return new Promise((resolve) => {
        getCorporationBySelf(mobile).then((response) => {
          if (!statusInclude(response.code)) return;
          this.corporationGroup = response.data;
          resolve(response);
        });
      });
    },

    //请求项目信息
    getProjectList(CPID) {
      queryProjectListByCpID(CPID).then((response) => {
        if (!statusInclude(response.code)) return this.$message.error(response.reason);
        this.projectList = response.data;
      });
    },

    // 请求电池信息列表
    getBatteryList({ page = 1, size = 20, batterySN = this.query }) {
      getBatteryList(page, size, batterySN).then((response) => {
        //TODO 查询无数据 查询成功返回数据结构不对
        if (!statusInclude(response.code)) return this.$message({ type: 'warning', message: response.reason });
        const {
          code,
          data: { total, list },
        } = response;
        if (statusInclude(code)) {
          this.batteryList = list;
          this.tableConfig.total = total || this.tableConfig.total;
        } else {
          this.$message({ type: 'error', message: '数据请求失败' });
        }
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getHeight);
  },
};
</script>
