<template>
  <el-form class="add-battery-form" ref="uBattery" :model="batteryInformation" :rules="rules" label-position="right" label-width="100px">
    <el-form-item label="电池序列号">
      <el-input v-model.trim="batteryInformation.batterySN" :disabled="Boolean(setModel)"></el-input>
    </el-form-item>
    <el-form-item label="生产商" prop="brand">
      <el-input v-model.trim="batteryInformation.brand"></el-input>
    </el-form-item>
    <el-form-item label="电池型号" prop="model">
      <el-select v-model="batteryInformation.model">
        <el-option :label="battery.label" :value="battery.value" v-for="battery in batteryTypes" :key="battery.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="项目信息" prop="PID">
      <GlobalSearchBar ref="globalSearchBar" @CPID:getCPID="getCPID" @get:checkedOption="getCheckedOption" />
    </el-form-item>
    <el-form-item label="备注" prop="context">
      <el-input type="textarea" v-model="batteryInformation.context"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import { examineBatterySNSN } from '@/utils/rules';
export default {
  name: 'AddBattey',
  props: ['setModel'],
  data() {
    return {
      batteryTypes: [
        {
          value: 'TB60',
          lable: 'TB60',
        },
        {
          value: 'TB55',
          lable: 'TB55',
        },
      ],
      batteryInformation: {
        batterySN: '',
        brand: '',
        UAVID: '',
        CPID: '',
        PID: '',
        model: '',
        context: '',
      },
      rules: {
        batterySN: [{ required: true, validator: examineBatterySNSN, trigger: 'blur' }],
        brand: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' },
        ],
        model: [{ required: true, message: '请选择电池型号', trigger: 'blur' }],
        PID: [{ required: true, message: '请选择项目信息', trigger: 'blur' }],
        context: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 40, message: '长度在 3 到 40 个字符', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    getCPID({ CPID }) {
      this.batteryInformation.CPID = CPID;
    },
    getCheckedOption(params) {
      this.batteryInformation.PID = params.PID;
    },
    submit() {
      return new Promise((resolve) => {
        this.$refs.uBattery.validate((valid) => {
          let response;
          if (valid) response = this.batteryInformation;
          else response = false;
          resolve(response);
        });
      });
    },
    resetForm() {
      this.$refs.uBattery.resetFields();
      this.$refs.globalSearchBar.handleRefresh();
      for (let key in this.batteryInformation) {
        this.batteryInformation[key] = '';
      }
    },
  },
};
</script>
